/* CSS */
import "/src/css/app.css";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// FS Lightbox
// import '/src/fslightbox/fslightbox.js';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
 import Alpine from 'alpinejs'
 window.Alpine = Alpine
 Alpine.start()